import {
  APP_TOKEN,
  APP_TOKEN_EC,
  APP_TOKEN_ECLIST,
  APP_TOKEN_FIRST_ACCESS,
} from '../utils/constants';

export interface Ec {
  Id: number;
  EventName: string;
  EventStatus: string;
  FranchiseId: number;
  FranchiseName: string;
  MonetixStatus: number;
  Cnpj: string;
  IsSubAcquirer?: 0 | 1;
}

export interface UserFirstAccess {
  isFirstAccess: boolean;
  document: string;
}

interface User {
  name: string;
  token: string;
  q2linkAuthToken?: string;
  requestId?: number;
  ecs?: Ec[];
}

const setUser = (user: User) => {
  localStorage.setItem(APP_TOKEN, JSON.stringify({
    ...user,
  }));
};

const setUserFirstAccess = (firstAccess: UserFirstAccess) => {
  localStorage.setItem(APP_TOKEN_FIRST_ACCESS, JSON.stringify({
    ...firstAccess,
  }));
};

const setEc = (ec: Ec) => {
  localStorage.setItem(APP_TOKEN_EC, JSON.stringify({
    ...ec,
  }));
};

const setEcList = (ecs: Ec[]) => {
  localStorage.setItem(APP_TOKEN_ECLIST, JSON.stringify(
    [...ecs],
  ));
};

const getUser = (): User => JSON.parse(localStorage.getItem(APP_TOKEN) || '');

const getUserFirstAccess = (): UserFirstAccess => JSON.parse(localStorage.getItem(APP_TOKEN_FIRST_ACCESS) || '');

const getEc = (): Ec => JSON.parse(localStorage.getItem(APP_TOKEN_EC) || '');

const getEcList = (): Ec[] => JSON.parse(localStorage.getItem(APP_TOKEN_ECLIST) || '');

const removeUser = () => localStorage.clear();

const getToken = () => {
  const { token } = JSON.parse(localStorage.getItem(APP_TOKEN) || '{}');
  return token;
};

export {
  setUser,
  setUserFirstAccess,
  setEc,
  setEcList,
  getUser,
  getUserFirstAccess,
  getEc,
  getEcList,
  removeUser,
  getToken,
};
